<!--
 * @Author: 月魂
 * @Date: 2021-11-28 15:15:15
 * @LastEditTime: 2022-09-19 11:41:36
 * @LastEditors: 月魂
 * @Description: 开屏页
 * @FilePath: \silk-road\src\views\Index.vue
-->
<template>
  <div class="box">
    <img src="http://image.wearetogether.com.cn/8th%2Fassets%2Findex%2Ftitle.png" alt="" class="bg">
    <div class="btn" @click.prevent="handleIn" @touchend.prevent="handleIn" @drag.prevent="handleIn">
      <img src="http://image.wearetogether.com.cn/8th%2Fassets%2Findex%2Floading.gif" alt="" class="loading" >
      <transition name="fade">
        <img src="http://image.wearetogether.com.cn/8th%2Fassets%2Findex%2Ftips.png"  @click.prevent="handleIn" @touchend.prevent="handleIn" alt="" class="tips" v-if="showTips">
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      showTips:false
    }
  },
  mounted() {
    this.showTips = true
    this.wxShareDef();
  },
  methods: {
    handleIn() {
      this.$router.push('/home')
    }
  }
}
</script>

<style lang="less" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.box {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url('http://image.wearetogether.com.cn/8th%2Fassets%2Fhome-bg.jpg') repeat;
  background-size: contain;
  position: absolute;
  .bg {
    display: block;
    width: 100%;
    max-width: 700PX;
    margin: auto;
  }
  .btn {
    width: 100%;
    max-width: 700PX;
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .loading {
    display: block;
    width: 40px;
    margin: auto;
  }
  .tips {
    display: block;
    width: 160px;
    margin: 10px auto 0;
  }

  @media (min-width: 1200PX) {
    .pc {
      display: block;
    }
    .mobile {
      display: none;
    }
  }
}
</style>
